import {API_URL} from '../config';

interface Props {
  id: string;
  oldPassword: string;
  newPassword: string;
  repeatNewPassword: string;
}

export const changePassword = async (data: Props) => {
  const res = await fetch(`${API_URL}/user/change-password`, {
    method: 'POST',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  return res.json();
};
