import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Navigate, Route, Routes} from 'react-router-dom';
import styled from 'styled-components';
import {AddEvent} from './components/Admin/AddEvent';
import {EditEvent} from './components/Admin/EditEvent';
import {Events} from './components/Admin/Events';
import {Settings} from './components/Admin/Settings';
import {NoRequiredAuth} from './components/NoRequiredAuth';
import {RequiredAuth} from './components/RequiredAuth';
import {getSampleAnalysis} from './fetch/event/getSampleAnalysis';
import {Admin} from './pages/Admin';
import {Login} from './pages/Login';
import {Main} from './pages/Main';
import {Types} from './pages/Types';
import {setSampleAnalysis} from './store/features/sampleAnalysis';
import {UserRoles} from './types/enums/UserRoles';

export const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const {event} = await getSampleAnalysis();
      dispatch(setSampleAnalysis(event));
    })();
  }, []);

  return (
    <Container>
      <Routes>
        <Route path={`/`} element={<Main />} />
        {/* <Route path={`/analysis`} element={<Types />} /> */}

        <Route element={<NoRequiredAuth />}>
          <Route path={`/login`} element={<Login />} />
        </Route>

        <Route element={<RequiredAuth roles={[UserRoles.ADMIN]} />}>
          <Route element={<Admin />}>
            <Route
              path={`/admin`}
              element={<Navigate to={'/admin/events'} />}
            />
            <Route path={`/admin/events`} element={<Events />} />
            <Route path={`/admin/settings`} element={<Settings />} />
            <Route path={`/admin/event/:eventId`} element={<EditEvent />} />
            <Route path={`/admin/add`} element={<AddEvent />} />
          </Route>
        </Route>
        <Route path={`*`} element={<Navigate to="/" />} />
      </Routes>
    </Container>
  );
};

const Container = styled.div`
  max-width: 1600px;
  margin: 0 auto;
`;
