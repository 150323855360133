import {useCallback} from 'react';
import styled from 'styled-components';
import {pixelAddToCart} from '../utils/pixelEvents';

export const PurchaseAction = () => {
  const subscribe = useCallback(() => {
    pixelAddToCart();
    window.location.href = 'https://easl.ink/9YIpY';
  }, []);

  return (
    <Container>
      <button onClick={subscribe}>Subscribe 💰</button>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  z-index: 100;
  bottom: 1rem;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 1600px;

  button {
    border: none;
    margin-right: 1rem;
    border-radius: ${(props) => props.theme.borderRadius.sm};
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.pink};
    font-size: ${(props) => props.theme.fontSize.sm};
    padding: ${(props) => props.theme.padding.sm}
      ${(props) => props.theme.padding.xl};
    cursor: pointer;
    white-space: nowrap;
  }
`;
