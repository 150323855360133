import styled from 'styled-components';
import {FormEvent, useState} from 'react';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {changePassword as changePass} from '../../fetch/changePassword';
import {useSelector} from 'react-redux';
import {RootState} from '../../store';

export const Settings = () => {
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const user = useSelector((store: RootState) => store.user) as any;
  const [changePassword, setChangePassword] = useState<any>({
    id: user.id,
    oldPassword: '',
    newPassword: '',
    repeatNewPassword: '',
  });

  const handleForm = async (e: FormEvent) => {
    e.preventDefault();
    setLoad(true);

    const res = await changePass(changePassword);

    if (res.ok) {
      toast.success('Hasło zostało zmienione 🎉');
      handleClear();
      navigate(`/admin/coupons`, {replace: true});
    } else {
      toast.error(`${res.message} ❌`);
    }

    setLoad(false);
  };

  const handleClear = () => {
    setChangePassword([
      {
        oldPassword: '',
        newPassword: '',
        repeatNewPassword: '',
      },
    ]);
  };

  return (
    <Container onSubmit={handleForm}>
      <p className="title">Zmień hasło</p>
      <div className="change-password">
        <input
          type="password"
          id="oldPassword"
          value={changePassword.oldPassword}
          placeholder="Stare hasło"
          onChange={(e) =>
            setChangePassword((prev: typeof changePassword) => ({
              ...prev,
              oldPassword: e.target.value,
            }))
          }
          required
        />
        <input
          type="password"
          id="newPassword"
          value={changePassword.newPassword}
          placeholder="Nowe hasło"
          onChange={(e) =>
            setChangePassword((prev: typeof changePassword) => ({
              ...prev,
              newPassword: e.target.value,
            }))
          }
          required
        />
        <input
          type="password"
          id="repeatNewPassword"
          value={changePassword.repeatNewPassword}
          placeholder="Powtórz nowe hasło"
          onChange={(e) =>
            setChangePassword((prev: typeof changePassword) => ({
              ...prev,
              repeatNewPassword: e.target.value,
            }))
          }
          required
        />
      </div>
      <div className="btn-box">
        <button type="submit" disabled={load}>
          Zapisz
        </button>
      </div>
    </Container>
  );
};

const Container = styled.form`
  margin: ${(props) => props.theme.margin.xl2}
    ${(props) => props.theme.margin.xl};

  .title {
    margin-bottom: ${(props) => props.theme.margin.base};
  }

  .change-password {
    input {
      padding: ${(props) => props.theme.padding.base};
      background-color: ${(props) => props.theme.colors.inputBackground};
      color: ${(props) => props.theme.colors.darkGray};
      border: none;
      font-weight: 600;
      border-radius: ${(props) => props.theme.borderRadius.sm};
      font-size: ${(props) => props.theme.fontSize.sm};
      margin-bottom: 0;

      &:focus {
        outline: none;
      }
    }

    input:not(:first-child) {
      margin-left: ${(props) => props.theme.margin.base};
    }
  }

  button {
    padding: ${(props) => props.theme.padding.base};
    background-color: ${(props) => props.theme.colors.blue};
    border: none;
    color: ${(props) => props.theme.colors.white};
    font-weight: 600;
    font-size: ${(props) => props.theme.fontSize.sm};
    border-radius: ${(props) => props.theme.borderRadius.m};
    cursor: pointer;
  }

  .btn-box {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: ${(props) => props.theme.margin.lg};
  }
`;
