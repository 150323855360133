import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import logo from '../assets/images/logo.webp';
import {getUser} from '../fetch/getUser';
import {RootState} from '../store';
import {setUser, User} from '../store/features/userSlice';
import {UserRoles} from '../types/enums/UserRoles';
import {AdminMenu} from './HeaderMenu/AdminMenu';
import {UserMenu} from './HeaderMenu/UserMenu';
import {MiniLoader} from './MiniLoader';

export const Header = () => {
  const dispatch = useDispatch();
  const user = useSelector((store: RootState) => store.user);
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen((prev) => !prev);

  useEffect(() => {
    (async () => {
      if (user === null) {
        const auth = await getUser();
        if (auth.ok) {
          dispatch(setUser(auth.user));
        } else {
          dispatch(setUser(false));
        }
      }
    })();
  }, []);

  return (
    <Container>
      <Link to="/" className="logo-item">
        <Logo src={logo} alt="logo" />
      </Link>
      <Wrapper>
        {user === null ? (
          <MiniLoader />
        ) : user ? (
          <ul>
            {/*<li>*/}
            {/*  <Email href="mailto: podwajam@gmail.com">*/}
            {/*    <i className="bx bxs-envelope" title="Odezwij się do mnie!"></i>*/}
            {/*  </Email>*/}
            {/*</li>*/}
            <li>
              <i className="bx bxs-user-circle" onClick={toggleMenu}></i>
              {isOpen && (
                <NavMenu>
                  {user && (user as User).role === UserRoles.ADMIN && (
                    <AdminMenu setState={setIsOpen} />
                  )}
                  {user && (user as User).role === UserRoles.USER && (
                    <UserMenu setState={setIsOpen} />
                  )}
                </NavMenu>
              )}
            </li>
          </ul>
        ) : (
          <ul>
            <li>
              <Link to={'https://www.analysis.mathtipster.com'}>
                <p className="types">Analysis</p>
              </Link>
            </li>
            {/*<li>*/}
            {/*  <Email href="mailto: podwajam@gmail.com">*/}
            {/*    <i className="bx bxs-envelope" title="Odezwij się do mnie!"></i>*/}
            {/*  </Email>*/}
            {/*</li>*/}
          </ul>
        )}
      </Wrapper>
    </Container>
  );
};

const NavMenu = styled.div`
  @-webkit-keyframes SHOW-BOX {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes SHOW-BOX {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-o-keyframes SHOW-BOX {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes SHOW-BOX {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  -webkit-animation: SHOW-BOX 0.5s ease;
  -moz-animation: SHOW-BOX 0.5s ease;
  -o-animation: SHOW-BOX 0.5s ease;
  animation: SHOW-BOX 0.5s ease;

  position: absolute;
  z-index: 2;
  width: fit-content;
  top: 130%;
  right: 0;
  background-color: ${(props) => props.theme.colors.red};
  color: ${(props) => props.theme.colors.white};
  padding: ${(props) => props.theme.padding.base};
  font-size: ${(props) => props.theme.fontSize.sm};
  border-radius: 0.5rem;

  li {
    cursor: pointer;
    white-space: nowrap;
  }

  .margin-bottom {
    margin-bottom: ${(props) => props.theme.margin.sm};
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  ul {
    display: flex;
  }

  li {
    display: flex;
    align-items: center;
    position: relative;

    .bxs-user-circle {
      margin-left: ${(props) => props.theme.margin.sm};
      font-size: ${(props) => props.theme.fontSize.lg};
      cursor: pointer;
    }

    .bxs-user-circle:hover {
      scale: 1.1;
    }

    .types {
      border: solid 1px;
      color: ${(props) => props.theme.colors.lightBlue};
      padding: calc(${(props) => props.theme.margin.sm} / 1.5)
        ${(props) => props.theme.margin.base};
      border-radius: ${(props) => props.theme.borderRadius.sm};
    }
  }
`;

const Container = styled.header`
  margin: ${(props) => props.theme.margin.xl2}
    ${(props) => props.theme.margin.xl} 0 ${(props) => props.theme.margin.xl};
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo-item { 
    display: flex;
    align-items: center;
  }
  }

  @media only screen and (max-width: 600px) {
    margin-top: ${(props) => props.theme.margin.xl};
    vertical-align: middle;
  }
`;

const Logo = styled.img`
  height: 2rem;
  cursor: pointer;

  @media only screen and (max-width: 420px) {
    height: 1.5rem;
  }
`;
