import {createSlice} from '@reduxjs/toolkit';
import {EventInterface} from '../../types/interfaces/EventInterface';

interface SampleAnalysisState {
  item: EventInterface | null;
}

const initialState: SampleAnalysisState = {
  item: null,
};

export const sampleAnalysisSlice = createSlice({
  name: 'sampleAnalysis',
  initialState,
  reducers: {
    setSampleAnalysis: (state, action) => {
      const event = action.payload;
      delete event.createdAt;
      delete event.updatedAt;
      state.item = event;
    },
  },
});

export const {setSampleAnalysis} = sampleAnalysisSlice.actions;
