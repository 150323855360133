import styled from 'styled-components';
import logo from '../assets/images/logo.webp';

export const Footer = () => {
  return (
    <Container>
      <Box>
        <Logo src={logo} alt="logo" />
        <ul>
          <li>
            <a href="https://www.mathtipster.com/terms.pdf">Terms</a>
          </li>
          <li>
            <a href="https://www.mathtipster.com/privacy-policy.pdf">
              Privacy Policy
            </a>
          </li>
        </ul>
      </Box>
      <p className="title">© 2024 All rights reserved</p>
    </Container>
  );
};

const Container = styled.header`
  margin: ${(props) => props.theme.margin.xl2}
    ${(props) => props.theme.margin.xl};
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${(props) => props.theme.fontSize.sm};

  @media only screen and (max-width: 800px) {
    flex-direction: column;

    .title {
      margin-top: ${(props) => props.theme.margin.base};
    }
  }

  @media only screen and (max-width: 600px) {
    margin-top: ${(props) => props.theme.margin.xl};
    margin-bottom: ${(props) => props.theme.margin.xl};
  }
`;

const Box = styled.div`
  display: flex;

  ul {
    display: flex;
    margin-left: ${(props) => props.theme.margin.lg};
    color: ${(props) => props.theme.colors.gray};

    li:not(:first-child) {
      margin-left: ${(props) => props.theme.margin.base};
    }
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;

    ul {
      margin-left: 0;
      margin-top: ${(props) => props.theme.margin.base};
    }
  }
`;

const Logo = styled.img`
  height: 1.5rem;
`;
