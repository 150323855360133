import {useSelector} from 'react-redux';
import styled from 'styled-components';
import {RootState} from '../../store';
import {Event} from './Event';

export const SampleAnalysis = () => {
  const {item: event} = useSelector((store: RootState) => store.sampleAnalysis);

  return (
    <div>
      {event ? (
        <Container>
          <h2 className="title">Sample analysis</h2>
          <Event event={event} />
        </Container>
      ) : null}
    </div>
  );
};

const Container = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: ${(props) => props.theme.margin.xl3}
    ${(props) => props.theme.margin.xl2} 0 ${(props) => props.theme.margin.xl2};

  .title {
    font-weight: 600;
  }

  @media only screen and (max-width: 800px) {
    margin-left: ${(props) => props.theme.margin.base};
    margin-right: ${(props) => props.theme.margin.base};
  }
`;
