import styled from 'styled-components';
import {Event} from '../Analysis/Event';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../store';
import {useEffect, useState} from 'react';
import {Loader} from '../Loader';
import {NoData} from '../NoData';
import {getEvents} from '../../fetch/event/getEvents';
import {setEvents} from '../../store/features/eventsSlice';

export const Events = () => {
  const {items: events} = useSelector((store: RootState) => store.events);
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (!events) {
        const {events: data} = await getEvents(15);
        dispatch(setEvents(data));
      }
      setLoader(false);
    })();
  }, [events]);

  return (
    <Container>
      {loader ? (
        <Loader />
      ) : events && events.length > 0 ? (
        events.map((event) => {
          return <Event key={event.id} event={event} admin={true} />;
        })
      ) : (
        <NoData />
      )}
    </Container>
  );
};

const Container = styled.div`
  margin: ${(props) => props.theme.margin.xl2}
    ${(props) => props.theme.margin.xl};

  @media only screen and (max-width: 800px) {
    margin-left: ${(props) => props.theme.margin.base};
    margin-right: ${(props) => props.theme.margin.base};
  }
`;
