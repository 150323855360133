import styled from 'styled-components';
import {ArrowSide} from '../types/enums/ArrowSide';
import leftArrow from '../assets/images/arrow-left.webp';
import rightArrow from '../assets/images/arrow-right.webp';

interface Props {
  side: ArrowSide;
}

export const Arrow = ({side}: Props) => {
  return (
    <Container side={side}>
      {side === ArrowSide.LEFT && <img src={rightArrow} alt="Left arrow" />}
      {side === ArrowSide.RIGHT && (
        <img src={leftArrow} className="arrow-right" alt="Right arrow" />
      )}
    </Container>
  );
};

const Container = styled.section<{side: ArrowSide}>`
  display: flex;
  justify-content: ${(props) =>
    props.side === ArrowSide.LEFT ? 'flex-start' : 'flex-end'};

  img {
    height: 20vh;
    margin: ${(props) => props.theme.margin.xl3}
      ${(props) => props.theme.margin.xl};
  }

  @media only screen and (max-width: 800px) {
    img {
      margin: ${(props) => props.theme.margin.xl}
        ${(props) => props.theme.margin.xl};
    }
  }
`;
