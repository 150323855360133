export const FAQS = [
  {
    id: 1,
    title: 'How are analyzes created?',
    body: 'I am passionate about sports and software development. To calculate probabilities, I use data from past events, and I also take into account current trends and situations regarding teams and athletes. All this data is analyzed using an algorithm. In addition, I follow media reports related to a given event.',
  },
  {
    id: 2,
    title: 'Why is there a limit on places?',
    body: 'The limit of people on the website is implemented to ensure effective customer service and to ensure real-time accessibility to users. With too many people using the platform, servicing and interacting with customers can become difficult and impossible to do effectively. By introducing a limit on people, you can ensure that each user will have the opportunity to get answers to their questions, and customer service will work efficiently and in line with expectations.',
  },
  {
    id: 3,
    title: 'How to purchase a subscription?',
    body: 'Payments related to the subscription of a given package are handled by the EasyCart (Stripe). The subscription is purchased for at least one month. During this time, the user has full access to the panel containing the picks for a given day.',
  },
  {
    id: 4,
    title: 'How do I cancel my subscription?',
    body: 'The subscription is automatically renewed every month. The user can cancel the subscription at any time in the EasyCart customer panel (https://cart.easy.tools/dashboard). The user should receive login details in an e-mail after purchasing the subscription.',
  },
  {
    id: 5,
    title: 'Where are the analyzes located?',
    body: 'The analyzes can be found at (https://analysis.mathtipster.com). The user should receive login details in an e-mail after purchasing the subscription.',
  },
];
