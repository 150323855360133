import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {AboutMe} from '../components/AboutMe';
import {SampleAnalysis} from '../components/Analysis/SampleAnalysis';
import {Arrow} from '../components/Arrow';
import {FaqBox} from '../components/Faq/FaqBox';
import {Footer} from '../components/Footer';
import {Header} from '../components/Header';
import {Info} from '../components/Info';
import {Loader} from '../components/Loader';
import {Newsletter} from '../components/Newsletter';
import {NoData} from '../components/NoData';
import {PurchaseAction} from '../components/PurchaseAction';
import {Subscription} from '../components/Subscription';
import {RootState} from '../store';
import {ArrowSide} from '../types/enums/ArrowSide';

export const Main = () => {
  const {item: event} = useSelector((store: RootState) => store.sampleAnalysis);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (event !== null) {
      setLoader(false);
    }
  }, [event]);

  return (
    <div>
      <PurchaseAction />
      <Header />
      <AboutMe />
      {loader ? <Loader /> : event ? <SampleAnalysis /> : <NoData />}
      <>
        <Arrow side={ArrowSide.RIGHT} />
        <Subscription />
        <Arrow side={ArrowSide.LEFT} />
        <Newsletter />
        <Arrow side={ArrowSide.RIGHT} />
        <FaqBox />
        <Arrow side={ArrowSide.LEFT} />
      </>

      <Info />
      <Footer />
    </div>
  );
};
