import {Header} from '../components/Header';
import {LoginForm} from '../components/Form/LoginForm';
import styled from 'styled-components';

export const Login = () => {
  return (
    <>
      <Header />
      <Wrapper>
        <h1>Zaloguj się</h1>
        <LoginForm />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  height: calc(100vh - 5rem);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    margin-bottom: ${(props) => props.theme.margin.lg};
    font-weight: 600;
  }
`;
