import styled from 'styled-components';
import {FormEvent, useState} from 'react';
import {login} from '../../fetch/login';
import {toast} from 'react-toastify';
import {useDispatch} from 'react-redux';
import {setUser} from '../../store/features/userSlice';
import {useNavigate} from 'react-router-dom';

export const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [form, setForm] = useState({
    email: '',
    password: '',
  });

  const handleForm = async (e: FormEvent) => {
    e.preventDefault();
    setLoad(true);

    const res = await login(form);

    if (res.ok) {
      dispatch(setUser(res.user));
      navigate('/typy', {replace: true});
    } else {
      toast.error(res.message);
    }

    setLoad(false);
  };

  return (
    <Container onSubmit={handleForm}>
      <input
        type="email"
        placeholder="Email"
        value={form.email}
        onChange={(e) => setForm((prev) => ({...prev, email: e.target.value}))}
        required
      />
      <input
        type="password"
        placeholder="Hasło"
        value={form.password}
        onChange={(e) =>
          setForm((prev) => ({...prev, password: e.target.value}))
        }
        required
      />
      <button type="submit" disabled={load}>
        Zaloguj
      </button>
    </Container>
  );
};

const Container = styled.form`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.darkBlue};
  padding: ${(props) => props.theme.padding.lg};
  border-radius: ${(props) => props.theme.borderRadius.sm};

  input {
    padding: ${(props) => props.theme.padding.base};
    background-color: ${(props) => props.theme.colors.inputBackground};
    color: ${(props) => props.theme.colors.darkGray};
    border: none;
    font-weight: 600;
    border-radius: ${(props) => props.theme.borderRadius.sm};
    font-size: ${(props) => props.theme.fontSize.sm};
    margin-bottom: ${(props) => props.theme.margin.base};

    &:focus {
      outline: none;
    }
  }

  button {
    padding: ${(props) => props.theme.padding.base};
    background-color: ${(props) => props.theme.colors.blue};
    border: none;
    color: ${(props) => props.theme.colors.white};
    font-weight: 600;
    font-size: ${(props) => props.theme.fontSize.sm};
    border-radius: ${(props) => props.theme.borderRadius.sm};
    cursor: pointer;
    margin-top: ${(props) => props.theme.margin.base};
  }
`;
