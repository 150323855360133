import styled from 'styled-components';
import aboutMeImg from '../assets/images/me.webp';

export const AboutMe = () => {
  return (
    <Container>
      <Image src={aboutMeImg} alt="Me" />
      <InfoBox>
        <p className="info-text">
          Welcome 👋 to a hub of unparalleled sports analysis crafted to elevate
          your understanding of the game! As a passionate aficionado of sports
          and a seasoned expert in mathematical analysis 📊, I bring forth over
          four years of dedicated expertise in the intricate world of
          bookmaking.
          <br /> <br />
          My approach is a fusion of astute insights derived from years of
          hands-on involvement, reinforced by cutting-edge AI assistance 🤖 and
          rigorous mathematical calculations. This amalgamation empowers me to
          curate highly effective strategies, ensuring meticulous selection of
          winning bets backed by a wealth of experience and a data-driven
          methodology. 💡
          <br /> <br />
          My commitment to excellence resonates through every analysis, offering
          you a pathway to informed decisions and lucrative opportunities within
          the realm of sports betting. Explore the realm of sports analysis
          redefined, where expertise meets innovation for unparalleled success
          in the dynamic landscape of sports wagering. 🏆💰
        </p>
        <div className="info-social-media">
          <a
            href="https://www.instagram.com/mathtipster"
            target="_blank"
            rel="noreferrer"
            title="Instagram"
          >
            <i className="bx bxl-instagram-alt"></i>
          </a>
          <a
            href="https://www.twitter.com/math_tipster"
            target="_blank"
            rel="noreferrer"
            title="Twitter"
          >
            <i className="bx bxl-twitter"></i>
          </a>
          {/* <a
            href="https://www.youtube.com/@MathTipster"
            target="_blank"
            rel="noreferrer"
            title="Youtube"
          >
            <i className="bx bxl-youtube"></i>
          </a> */}
          {/* <a
            href="https://www.tiktok.com/@mathtipster"
            target="_blank"
            rel="noreferrer"
            title="TikTok"
          >
            <i className="bx bxl-tiktok"></i>
          </a> */}
          <a href="mailto: contact@mathtipster.com" title="email">
            <i className="bx bxs-envelope"></i>
          </a>
        </div>
      </InfoBox>
    </Container>
  );
};

const Container = styled.section`
  display: flex;
  align-items: center;
  margin: ${(props) => props.theme.margin.xl3}
    ${(props) => props.theme.margin.xl2} 0 ${(props) => props.theme.margin.xl2};

  @media only screen and (max-width: 800px) {
    flex-direction: column;
    margin-left: ${(props) => props.theme.margin.lg};
    margin-right: ${(props) => props.theme.margin.lg};
  }

  @media only screen and (max-width: 600px) {
    margin-left: ${(props) => props.theme.margin.lg};
    margin-right: ${(props) => props.theme.margin.lg};
  }
`;

const Image = styled.img`
  height: 12rem;
  margin-right: ${(props) => props.theme.margin.xl2};

  @media only screen and (max-width: 800px) {
    margin-right: 0;
    margin-bottom: ${(props) => props.theme.margin.xl};
  }
`;

const InfoBox = styled.div`
  .info-social-media {
    margin-top: ${(props) => props.theme.margin.lg};
    font-size: ${(props) => props.theme.fontSize.lg};
    color: ${(props) => props.theme.colors.lightBlue};

    .bx {
      cursor: pointer;
    }

    .bx:hover {
      scale: 1.1;
    }
  }

  .bxl-instagram-alt {
    margin-right: ${(props) => props.theme.margin.sm};
  }

  .bxl-tiktok {
    margin-right: ${(props) => props.theme.margin.sm};
  }

  .bxl-youtube {
    margin-right: ${(props) => props.theme.margin.sm};
  }

  .bxl-twitter {
    margin-right: ${(props) => props.theme.margin.sm};
  }

  @media only screen and (max-width: 800px) {
    .info-social-media {
      text-align: center;
    }
  }
`;
