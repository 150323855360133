import styled from 'styled-components';
import {FAQS} from '../../data/faqs.data';
import {Faq} from './Faq';

export const FaqBox = () => {
  return (
    <Container>
      <h2 className="title">FAQ</h2>
      {FAQS.map((faq) => {
        return <Faq key={faq.id} faq={faq} />;
      })}
    </Container>
  );
};

const Container = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 ${(props) => props.theme.margin.xl2};

  .title {
    font-weight: 600;
  }

  @media only screen and (max-width: 800px) {
    margin-left: ${(props) => props.theme.margin.base};
    margin-right: ${(props) => props.theme.margin.base};
  }
`;
