import {FormEvent, useState} from 'react';
import {toast} from 'react-toastify';
import styled from 'styled-components';
import me from '../assets/images/newsletter.webp';
import {newsletter} from '../fetch/newsletter';

export const Newsletter = () => {
  const [email, setEmail] = useState('');
  const [load, setLoad] = useState(false);

  const handleForm = async (e: FormEvent) => {
    e.preventDefault();
    setLoad(true);

    const res = await newsletter(email);

    if (res.ok) {
      toast.success('Witaj w drużynie! 🥳');
    } else {
      toast.error(res.message);
    }

    setLoad(false);
  };

  return (
    <Container>
      <Img src={me} alt="me"></Img>
      <p className="newsletter-title">Newsletter for tipsters 📮</p>
      <p className="newsletter-description">
        Do you like my content? Join the newsletter to stay up to date with the
        typing world, gain access to additional materials and take participation
        in competitions with prizes! 🎁
      </p>
      <form className="newsletter-form" onSubmit={handleForm}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit" disabled={load}>
          {!load ? 'Subscribe' : '...saving'}
        </button>
      </form>
    </Container>
  );
};

const Container = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 ${(props) => props.theme.margin.xl2};
  background-color: ${(props) => props.theme.colors.darkBlue};
  padding: ${(props) => props.theme.padding.lg}
    ${(props) => props.theme.padding.xl};
  border-radius: ${(props) => props.theme.borderRadius.base};

  .newsletter-title {
    font-weight: 600;
    margin-bottom: ${(props) => props.theme.margin.base};
  }

  .newsletter-description {
    width: 85%;
    color: ${(props) => props.theme.colors.gray};
    margin-bottom: ${(props) => props.theme.margin.base};
  }

  .newsletter-form {
    input {
      padding: ${(props) => props.theme.padding.base};
      background-color: ${(props) => props.theme.colors.inputBackground};
      color: ${(props) => props.theme.colors.darkGray};
      border: none;
      font-weight: 600;
      border-top-left-radius: ${(props) => props.theme.borderRadius.sm};
      border-bottom-left-radius: ${(props) => props.theme.borderRadius.sm};
      font-size: ${(props) => props.theme.fontSize.sm};

      &:focus {
        outline: none;
      }
    }

    button {
      padding: ${(props) => props.theme.padding.base};
      background-color: ${(props) => props.theme.colors.blue};
      border: none;
      color: ${(props) => props.theme.colors.white};
      font-weight: 600;
      font-size: ${(props) => props.theme.fontSize.sm};
      border-top-right-radius: ${(props) => props.theme.borderRadius.sm};
      border-bottom-right-radius: ${(props) => props.theme.borderRadius.sm};
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 1000px) {
    .newsletter-description {
      font-size: calc(${(props) => props.theme.fontSize.sm});
      line-height: 1.7rem;
    }
  }

  @media only screen and (max-width: 600px) {
    .newsletter-description {
      font-size: calc(${(props) => props.theme.fontSize.sm} / 1.2);
      line-height: 1.5rem;
    }
  }

  @media only screen and (max-width: 800px) {
    margin-left: ${(props) => props.theme.margin.base};
    margin-right: ${(props) => props.theme.margin.base};
    padding-top: ${(props) => props.theme.padding.xl4};

    .newsletter-description {
      width: 100%;
    }
  }

  @media only screen and (max-width: 600px) {
    padding: ${(props) => props.theme.padding.xl3}
      ${(props) => props.theme.padding.lg} ${(props) => props.theme.padding.lg}
      ${(props) => props.theme.padding.lg};

    .newsletter-form {
      width: 100%;
      input {
        width: 70%;
      }

      button {
        width: 30%;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .newsletter-form {
      input {
        width: 60%;
      }

      button {
        width: 40%;
      }
    }
  }

  @media only screen and (max-width: 380px) {
    .newsletter-form {
      input {
        width: 50%;
      }

      button {
        width: 50%;
      }
    }
  }
`;

const Img = styled.img`
  width: 10rem;
  position: absolute;
  right: -1.5rem;
  top: -4rem;

  @media only screen and (max-width: 800px) {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: -6rem;
  }

  @media only screen and (max-width: 600px) {
    width: 8rem;
    top: -5rem;
  }
`;
