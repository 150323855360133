import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../store';
import {useEffect} from 'react';
import {getUser} from '../fetch/getUser';
import {setUser, User} from '../store/features/userSlice';
import {Loader} from './Loader';
import {Navigate, Outlet} from 'react-router-dom';
import styled from 'styled-components';

export const NoRequiredAuth = () => {
  const user = useSelector((store: RootState) => store.user);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const auth = await getUser();
      if (auth.ok) {
        dispatch(setUser(auth.user));
      } else {
        dispatch(setUser(false));
      }
    })();
  }, []);

  return user === null ? (
    <Container>
      <Loader />
    </Container>
  ) : (user as User).role ? (
    <Navigate to={'/typy'} />
  ) : (
    <Outlet />
  );
};

const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
`;
