import {API_URL} from '../config';

export const logout = async () => {
  const res = await fetch(`${API_URL}/user/logout`, {
    method: 'GET',
    credentials: 'include',
    mode: 'cors',
  });

  return res.json();
};
