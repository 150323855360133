import styled from 'styled-components';

export const NoData = () => {
  return <Container>no data</Container>;
};

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: ${(props) => props.theme.margin.xl4} 0;

  @media only screen and (max-width: 800px) {
    margin-bottom: ${(props) => props.theme.margin.xl5};
  }
`;
