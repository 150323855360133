import styled from 'styled-components';
import {Link, useNavigate} from 'react-router-dom';
import {logout} from '../../fetch/logout';
import {toast} from 'react-toastify';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {setUser} from '../../store/features/userSlice';

interface Props {
  setState: (value: (prev: boolean) => boolean) => void;
}

export const UserMenu = ({setState}: Props) => {
  const [load, setLoad] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toggleMenu = () => setState((prev) => !prev);

  const handleLogout = async () => {
    setLoad(true);
    const res = await logout();

    if (res.ok) {
      dispatch(setUser(false));
      navigate('/', {replace: true});
    } else {
      toast.success(res.message);
    }
    setLoad(false);
  };

  return (
    <Container>
      <Link to={'/analysis'} onClick={toggleMenu}>
        <li>Typy</li>
      </Link>
      <button className="logout-btn" disabled={load} onClick={toggleMenu}>
        <li onClick={handleLogout}>Wyloguj</li>
      </button>
    </Container>
  );
};

const Container = styled.ul`
  display: flex;
  flex-direction: column;
  // margin-right: ${(props) => props.theme.margin.base};

  .logout-btn {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  li {
    // margin-right: ${(props) => props.theme.margin.base};
    cursor: pointer;
  }
`;
