import {API_URL} from '../../config';
import {EventInterfaceReq} from '../../types/interfaces/EventInterface';

export const createEvent = async (event: EventInterfaceReq) => {
  const res = await fetch(`${API_URL}/event`, {
    method: 'POST',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(event),
  });

  return res.json();
};
