import {FormEvent, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import styled from 'styled-components';
import {createEvent} from '../../fetch/event/createEvent';
import {setEvent as setEventSlice} from '../../store/features/eventsSlice';
import {EventResult} from '../../types/enums/EventResult';
import {EventInterfaceReq} from '../../types/interfaces/EventInterface';

export const AddEvent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [event, setEvent] = useState<EventInterfaceReq>({
    date: '',
    sport: '',
    league: '',
    name: '',
    type: '',
    sampleAnalysis: false,
    resultType: EventResult.UNDEFINED,
    result: '',
    rate: 0,
    analysis: '',
  });

  const handleForm = async (e: FormEvent) => {
    e.preventDefault();
    setLoad(true);

    const res = await createEvent(event);

    if (res.ok) {
      toast.success('Analiza została dodana 🎉');
      dispatch(setEventSlice(res.event));
      handleClear();
      navigate(`/admin/events`, {replace: true});
    } else {
      toast.error('Analiza nie została dodana ❌');
    }

    setLoad(false);
  };

  const handleClear = () => {
    setEvent({
      date: '',
      sport: '',
      league: '',
      name: '',
      type: '',
      sampleAnalysis: false,
      resultType: EventResult.UNDEFINED,
      result: '',
      rate: 0,
      analysis: '',
    });
  };

  return (
    <Container onSubmit={handleForm}>
      <input
        id="date"
        type="datetime-local"
        value={event.date}
        placeholder="Data"
        onChange={(e) => setEvent((prev) => ({...prev, date: e.target.value}))}
        required
      />
      <input
        id="sport"
        type="text"
        value={event.sport}
        onChange={(e) => setEvent((prev) => ({...prev, sport: e.target.value}))}
        placeholder="Dyscyplina"
        required
      />
      <input
        id="league"
        type="text"
        value={event.league}
        onChange={(e) =>
          setEvent((prev) => ({...prev, league: e.target.value}))
        }
        placeholder="Liga/Turniej"
        required
      />
      <input
        id="name"
        type="text"
        value={event.name}
        onChange={(e) => setEvent((prev) => ({...prev, name: e.target.value}))}
        placeholder="Uczestnicy zdarzenia"
        required
      />
      <input
        id="type"
        type="text"
        placeholder="Typ"
        value={event.type}
        onChange={(e) => setEvent((prev) => ({...prev, type: e.target.value}))}
        required
      />
      <input
        id="rate"
        type="number"
        value={event.rate}
        onChange={(e) =>
          setEvent((prev) => ({...prev, rate: Number(e.target.value)}))
        }
        placeholder="Kurs"
        required
      />
      <textarea
        id="analysis"
        rows={10}
        placeholder="Analiza"
        value={event.analysis}
        onChange={(e) =>
          setEvent((prev) => ({...prev, analysis: e.target.value}))
        }
        required
      />
      <div className="btn-box">
        <button type="submit" disabled={load}>
          Dodaj
        </button>
        <button type="button" className="clear-btn" onClick={handleClear}>
          Wyczyść
        </button>
      </div>
    </Container>
  );
};

const Container = styled.form`
  margin: ${(props) => props.theme.margin.xl2}
    ${(props) => props.theme.margin.xl};
  display: flex;
  flex-direction: column;

  input {
    padding: ${(props) => props.theme.padding.base};
    background-color: ${(props) => props.theme.colors.inputBackground};
    color: ${(props) => props.theme.colors.darkGray};
    border: none;
    font-weight: 600;
    border-radius: ${(props) => props.theme.borderRadius.sm};
    font-size: ${(props) => props.theme.fontSize.sm};
    margin-bottom: ${(props) => props.theme.margin.base};

    &:focus {
      outline: none;
    }

    &::-webkit-calendar-picker-indicator {
      filter: invert(1);
    }
  }

  textarea {
    padding: ${(props) => props.theme.padding.base};
    background-color: ${(props) => props.theme.colors.inputBackground};
    color: ${(props) => props.theme.colors.darkGray};
    border: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    border-radius: ${(props) => props.theme.borderRadius.sm};
    font-size: ${(props) => props.theme.fontSize.sm};
    margin-bottom: ${(props) => props.theme.margin.base};
    resize: vertical;

    &:focus {
      outline: none;
    }

    &::-webkit-calendar-picker-indicator {
      filter: invert(1);
    }
  }

  button {
    padding: ${(props) => props.theme.padding.base};
    background-color: ${(props) => props.theme.colors.blue};
    border: none;
    color: ${(props) => props.theme.colors.white};
    font-weight: 600;
    font-size: ${(props) => props.theme.fontSize.sm};
    border-radius: ${(props) => props.theme.borderRadius.m};
    cursor: pointer;
  }

  .clear-btn {
    background-color: ${(props) => props.theme.colors.red};
    margin-left: ${(props) => props.theme.margin.base};
  }

  .summary-box {
    #bid {
      margin-left: ${(props) => props.theme.margin.base};
      width: 30%;
    }

    #date {
      width: 30%;
    }
  }

  .event-box {
    width: 100%;

    .event-title {
      margin-bottom: ${(props) => props.theme.margin.base};
      padding-left: ${(props) => props.theme.padding.base};
      font-size: ${(props) => props.theme.fontSize.sm};
    }

    .input-box {
      width: 100%;
      margin-bottom: ${(props) => props.theme.margin.base};
      display: flex;
      gap: 2%;

      #time {
        width: 10%;
      }

      #discipline {
        width: 14%;
      }

      #league {
        width: 14%;
      }

      #name {
        width: 14%;
      }

      #type {
        width: 14%;
      }

      #rate {
        width: 10%;
      }

      .event-btn-box {
        width: 10%;

        #remove {
          margin-left: ${(props) => props.theme.margin.base};
        }
      }
    }

    input {
      margin-bottom: 0;
    }
  }

  .btn-box {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: ${(props) => props.theme.margin.lg};
  }

  @media only screen and (max-width: 1200px) {
    margin: ${(props) => props.theme.margin.xl2}
      ${(props) => props.theme.margin.base};

    .summary-box {
      #bid {
        margin-left: 2%;
        width: 49%;
      }

      #date {
        width: 49%;
      }
    }

    .event-box {
      .input-box {
        flex-direction: column;

        #time {
          width: 100%;
        }

        #discipline {
          width: 100%;
        }

        #league {
          width: 100%;
        }

        #name {
          width: 100%;
        }

        #type {
          width: 100%;
        }

        #rate {
          width: 100%;
        }
        .event-btn-box {
          width: 100%;
          display: flex;
          justify-content: flex-end;

          #remove {
            margin-left: ${(props) => props.theme.margin.base};
          }
        }
      }

      .input-box > * {
        margin-bottom: ${(props) => props.theme.margin.base};
      }
    }
  }
`;
