import {FormEvent, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import styled from 'styled-components';
import {getEvents} from '../../fetch/event/getEvents';
import {updateEvent} from '../../fetch/event/updateEvent';
import {RootState} from '../../store';
import {
  setEvents,
  updateEvent as updateEventRedux,
} from '../../store/features/eventsSlice';
import {setSampleAnalysis} from '../../store/features/sampleAnalysis';
import {EventResult} from '../../types/enums/EventResult';
import {Loader} from '../Loader';

export const EditEvent = () => {
  const {eventId} = useParams();
  const {items: events} = useSelector((store: RootState) => store.events);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loadPage, setLoadPage] = useState<null | boolean>(null);
  const [load, setLoad] = useState(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [eventState, setEventState] = useState({
    id: '',
    date: '',
    sport: '',
    league: '',
    name: '',
    type: '',
    sampleAnalysis: false,
    resultType: EventResult.UNDEFINED,
    result: '',
    rate: 0,
    analysis: '',
  });

  useEffect(() => {
    (async () => {
      if (!eventId) {
        navigate('/admin/events', {replace: true});
      }

      let eventEntity = events?.find((el) => el.id === eventId);

      if (!eventEntity) {
        const res = await getEvents(15);
        dispatch(setEvents(res.events));
      }

      if (eventEntity) {
        setEventState(eventEntity);
        setChecked(eventEntity.sampleAnalysis);
      }

      setLoadPage(true);
    })();
  }, [events]);

  const handleForm = async (e: FormEvent) => {
    e.preventDefault();
    setLoad(true);

    if (eventId) {
      const res = await updateEvent(eventState);

      if (res.ok) {
        navigate('/admin/events', {replace: true});
        toast.success('Analiza została zaktualizowana 🎉');
        dispatch(updateEventRedux(res.event));

        if (eventState.sampleAnalysis) {
          dispatch(setSampleAnalysis(eventState));
        }
      } else {
        toast.error('Analiza nie została zaktualizowana ❌');
      }
    }
    setLoad(false);
  };

  const handleBackLink = () => {
    navigate('/admin/events', {replace: true});
  };

  const handleChange = () => {
    setChecked(!checked);
    setEventState((prev) => ({...prev, sampleAnalysis: !prev.sampleAnalysis}));
  };

  if (loadPage === null) {
    return <Loader />;
  }

  return (
    <Container>
      <form className="popup" onSubmit={handleForm}>
        <button type="button" className="back-btn" onClick={handleBackLink}>
          Wróć
        </button>
        <label htmlFor="date">Data:</label>
        <input
          id="date"
          type="datetime-local"
          value={eventState.date}
          placeholder="Data"
          onChange={(e) =>
            setEventState((prev) => ({...prev, date: e.target.value}))
          }
          required
        />
        <label htmlFor="sport">Dyscyplina:</label>
        <input
          id="sport"
          type="text"
          value={eventState.sport}
          onChange={(e) =>
            setEventState((prev) => ({...prev, sport: e.target.value}))
          }
          placeholder="Dyscyplina"
          required
        />
        <label htmlFor="league">Rozgrywki:</label>
        <input
          id="league"
          type="text"
          value={eventState.league}
          onChange={(e) =>
            setEventState((prev) => ({...prev, league: e.target.value}))
          }
          placeholder="Liga/Turniej"
          required
        />
        <label htmlFor="name">Zdarzenie:</label>
        <input
          id="name"
          type="text"
          value={eventState.name}
          onChange={(e) =>
            setEventState((prev) => ({...prev, name: e.target.value}))
          }
          placeholder="Uczestnicy zdarzenia"
          required
        />
        <label htmlFor="type">Typ:</label>
        <input
          id="type"
          type="text"
          placeholder="Typ"
          value={eventState.type}
          onChange={(e) =>
            setEventState((prev) => ({...prev, type: e.target.value}))
          }
          required
        />
        <label htmlFor="rate">Kurs:</label>
        <input
          id="rate"
          type="number"
          value={eventState.rate}
          onChange={(e) =>
            setEventState((prev) => ({...prev, rate: Number(e.target.value)}))
          }
          placeholder="Kurs"
          required
        />
        <label htmlFor="result">Rezultat:</label>
        <select
          name="resultType"
          value={eventState.resultType}
          onChange={(e) =>
            setEventState((prev) => ({
              ...prev,
              resultType: e.target.value as EventResult,
            }))
          }
          required
        >
          <option value={EventResult.UNDEFINED}>[❔] Nierozstrzygnięty</option>
          <option value={EventResult.WIN}>[✅] Weszło</option>
          <option value={EventResult.LOSE}>[❌] Nie weszło</option>
        </select>
        <input
          id="result"
          type="text"
          value={eventState.result}
          onChange={(e) =>
            setEventState((prev) => ({...prev, result: e.target.value}))
          }
          placeholder="Rezultat"
        />
        <label htmlFor="analysis">Analiza:</label>
        <textarea
          id="analysis"
          rows={10}
          placeholder="Analiza"
          value={eventState.analysis}
          onChange={(e) =>
            setEventState((prev) => ({...prev, analysis: e.target.value}))
          }
          required
        />
        <div>
          <label htmlFor="sampleAnalysis">Przykładowa analiza:</label>
          <input
            id="sampleAnalysis"
            type="checkbox"
            checked={checked}
            onChange={handleChange}
          />
        </div>
        <div className="box-btn">
          <button type="submit" disabled={load}>
            Zapisz
          </button>
        </div>
      </form>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;

  margin: ${(props) => props.theme.margin.xl2}
    ${(props) => props.theme.margin.xl};

  .popup {
    background-color: ${(props) => props.theme.colors.lightBlue};
    padding: ${(props) => props.theme.padding.lg};
    color: ${(props) => props.theme.colors.white};
    width: 80vw;
    overflow: auto;
    display: flex;
    flex-direction: column;

    label {
      color: ${(props) => props.theme.colors.inputBackground};
      margin-left: ${(props) => props.theme.margin.base};
      font-size: ${(props) => props.theme.fontSize.base};
    }

    input,
    select {
      padding: ${(props) => props.theme.padding.base};
      background-color: ${(props) => props.theme.colors.inputBackground};
      color: ${(props) => props.theme.colors.darkGray};
      border: none;
      font-weight: 600;
      border-radius: ${(props) => props.theme.borderRadius.sm};
      font-size: ${(props) => props.theme.fontSize.sm};
      margin-bottom: ${(props) => props.theme.margin.base};

      &:focus {
        outline: none;
      }

      &::-webkit-calendar-picker-indicator {
        filter: invert(1);
      }
    }

    input[type='checkbox'] {
      /* Double-sized Checkboxes */
      -ms-transform: scale(2); /* IE */
      -moz-transform: scale(2); /* FF */
      -webkit-transform: scale(2); /* Safari and Chrome */
      -o-transform: scale(2); /* Opera */
      transform: scale(2);
      padding: ${(props) => props.theme.margin.base};
      margin-left: ${(props) => props.theme.margin.base};
    }

    select {
      cursor: pointer;
    }

    textarea {
      padding: ${(props) => props.theme.padding.base};
      background-color: ${(props) => props.theme.colors.inputBackground};
      color: ${(props) => props.theme.colors.darkGray};
      border: none;
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;
      border-radius: ${(props) => props.theme.borderRadius.sm};
      font-size: ${(props) => props.theme.fontSize.sm};
      margin-bottom: ${(props) => props.theme.margin.base};
      resize: vertical;

      &:focus {
        outline: none;
      }

      &::-webkit-calendar-picker-indicator {
        filter: invert(1);
      }
    }

    button {
      padding: ${(props) => props.theme.padding.base};
      background-color: ${(props) => props.theme.colors.blue};
      border: none;
      color: ${(props) => props.theme.colors.white};
      font-weight: 600;
      font-size: ${(props) => props.theme.fontSize.sm};
      border-radius: ${(props) => props.theme.borderRadius.m};
      cursor: pointer;
    }

    .back-btn {
      margin-bottom: ${(props) => props.theme.margin.base};
    }

    .box-btn {
      display: flex;
      justify-content: center;

      .close-btn {
        background-color: ${(props) => props.theme.colors.red};
        margin-right: ${(props) => props.theme.margin.base};
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .popup {
      width: 80vw;
    }
  }

  @media only screen and (max-width: 800px) {
    margin-left: ${(props) => props.theme.margin.base};
    margin-right: ${(props) => props.theme.margin.base};
  }

  @media only screen and (max-width: 600px) {
    .popup {
      width: 90vw;
    }
  }
`;
