import {API_URL} from '../config';

export const newsletter = async (email: string) => {
  const res = await fetch(`${API_URL}/mailer/subscribe`, {
    method: 'POST',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({email}),
  });

  return res.json();
};
