import styled from 'styled-components';
import img18 from '../assets/images/18.webp';

export const Info = () => {
  return (
    <Container>
      <Img src={img18} alt="18" />
      <p className="info-text">
        The mathtipster.com is intended for people over 18 years of age. Don't
        forget that sports betting involves a lot of risk. Any symptoms of
        addiction should be consulted immediately at an appropriate clinic.
        Remember to play responsibly. Always check the site you are playing on.
        We would like to remind you that bookmaking is gambling. It may cause
        financial loss and addiction. My analyzes are not investment advice 🌐
        begambleaware.org
      </p>
    </Container>
  );
};

const Img = styled.img`
  width: 7rem;
  position: absolute;
  right: -1.5rem;
  top: -4rem;

  @media only screen and (max-width: 800px) {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
`;

const Container = styled.section`
  position: relative;
  margin: 0 ${(props) => props.theme.margin.xl2};
  background-color: ${(props) => props.theme.colors.purple};
  padding: ${(props) => props.theme.padding.lg}
    ${(props) => props.theme.padding.xl};
  border-radius: ${(props) => props.theme.borderRadius.base};
  font-size: ${(props) => props.theme.fontSize.sm};
  color: ${(props) => props.theme.colors.gray};

  .info-text {
    width: 95%;
    line-height: 1.7rem;
  }

  @media only screen and (max-width: 1000px) {
    font-size: calc(${(props) => props.theme.fontSize.sm});
    line-height: 1.7rem;
  }

  @media only screen and (max-width: 800px) {
    margin-left: ${(props) => props.theme.margin.base};
    margin-right: ${(props) => props.theme.margin.base};
    padding-top: ${(props) => props.theme.padding.xl3};

    .info-text {
      width: 100%;
    }
  }

  @media only screen and (max-width: 600px) {
    padding: ${(props) => props.theme.padding.xl3}
      ${(props) => props.theme.padding.lg} ${(props) => props.theme.padding.lg}
      ${(props) => props.theme.padding.lg};
    font-size: calc(${(props) => props.theme.fontSize.sm} / 1.2);
    line-height: 1.5rem;
  }
`;
