import {API_URL} from '../config';

interface User {
  email: string;
  password: string;
}

export const login = async (user: User) => {
  const res = await fetch(`${API_URL}/user/login`, {
    method: 'POST',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  });

  return res.json();
};
