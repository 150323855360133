import {API_URL} from '../../config';
import {EventInterface} from '../../types/interfaces/EventInterface';

export const updateEvent = async (event: EventInterface) => {
  const res = await fetch(`${API_URL}/event`, {
    method: 'PATCH',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(event),
  });

  return res.json();
};
