import {useCallback} from 'react';
import styled from 'styled-components';
import mock from '../assets/images/mock.webp';
import {pixelAddToCart} from '../utils/pixelEvents';

export const Subscription = () => {
  const subscribe = useCallback(() => {
    pixelAddToCart();
    window.location.href = 'https://easl.ink/9YIpY';
  }, []);

  return (
    <>
      <Title className="title">
        Buy subscriptions{' '}
        <span className="blink">The number of places is limited!</span>
      </Title>
      <Container>
        <Price>
          <span>$14.99</span>
        </Price>
        <div className="img">
          <Img src={mock} alt={'Pakiet'}></Img>
        </div>
        <div className="text">
          <p>
            <span>PLATINUM</span> Package 💎
          </p>
          <ul>
            <li>
              <i className="bx bx-check"></i>{' '}
              <span>
                30-day access to the analysis platform (monthly subscription),
              </span>
            </li>
            <li>
              <i className="bx bx-check"></i>{' '}
              <span>Daily publication of at least several analyzes</span>
            </li>
            <li>
              <i className="bx bx-check"></i>{' '}
              <span>
                Calculating the chances of success based on mathematical
                equations, proprietary algorithms, AI assistance and a
                meticulous analysis of a given event
              </span>
            </li>
            <li>
              <i className="bx bx-check"></i>{' '}
              <span>Several hundred satisfied customers!</span>
            </li>
            <li>
              <i className="bx bx-check"></i>{' '}
              <span>
                You can see the results on Instagram -&gt;{' '}
                <a href="https://www.instagram.com/mathtipster/">MathTipster</a>
              </span>
            </li>
          </ul>
          <div className="btn-box">
            <button onClick={subscribe}>Choose package 💰</button>
          </div>
        </div>
      </Container>
    </>
  );
};

const Price = styled.div`
  border-radius: ${(props) => props.theme.borderRadius.sm};
  position: absolute;
  background-color: ${(props) => props.theme.colors.blue};
  color: ${(props) => props.theme.colors.white};
  padding: ${(props) => props.theme.padding.base}
    ${(props) => props.theme.padding.xl};
  right: -1rem;
  top: -1rem;

  span {
    white-space: nowrap;
  }

  @media only screen and (max-width: 600px) {
    padding: ${(props) => props.theme.padding.sm}
      ${(props) => props.theme.padding.base};
  }
`;

const Img = styled.img`
  width: 60%;
  height: auto;
  object-fit: contain;

  @media only screen and (max-width: 1000px) {
    width: 70%;
  }

  @media only screen and (max-width: 800px) {
    width: 30%;
  }

  @media only screen and (max-width: 600px) {
    width: 40%;
  }
`;

const Title = styled.h2`
  position: relative;
  margin: 0 ${(props) => props.theme.margin.xl2};
  font-weight: 600;
  margin-bottom: ${(props) => props.theme.margin.xl};
  white-space: nowrap;
  display: flex;
  
    .blink {
      animation: blinker 2s linear infinite;
      color: ${(props) => props.theme.colors.pink};
      font-size: ${(props) => props.theme.fontSize.sm};
      margin-left: ${(props) => props.theme.margin.base};
    }

    @keyframes blinker {
      50% {
        opacity: 30%;
      }
    }

    @media only screen and (max-width: 800px) {
      margin-left: ${(props) => props.theme.margin.base};
      margin-right: ${(props) => props.theme.margin.base};
    }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    
    .blink {
      margin-left: 0;
      margin-top: ${(props) => props.theme.margin.sm};
      font-size: calc(${(props) => props.theme.fontSize.sm}/1.2);
    }
    }
  }
`;

const Container = styled.section`
  position: relative;
  margin: 0 ${(props) => props.theme.margin.xl2};
  background-color: ${(props) => props.theme.colors.lightBlue};
  padding: ${(props) => props.theme.padding.lg}
    ${(props) => props.theme.padding.xl};
  border-radius: ${(props) => props.theme.borderRadius.base};
  color: ${(props) => props.theme.colors.darkBlue};
  display: flex;

  .img {
    width: 30%;
    display: flex;
    justify-content: center;
    align-content: center;
  }

  .text {
    width: 70%;

    p {
      font-weight: 600;
      margin-bottom: ${(props) => props.theme.margin.sm};

      span {
        color: ${(props) => props.theme.colors.pink};
        font-weight: 600;
      }
    }

    ul {
      li {
        display: flex;
        span {
          padding-top: calc(${(props) => props.theme.padding.sm} / 1.5);
        }
        .bx {
          color: ${(props) => props.theme.colors.pink};
          font-size: ${(props) => props.theme.fontSize.xl};
          margin-right: ${(props) => props.theme.margin.base};
        }

        @media only screen and (max-width: 600px) {
          .bx {
            font-size: ${(props) => props.theme.fontSize.sm};
            margin-right: ${(props) => props.theme.margin.sm};
            padding-top: calc(${(props) => props.theme.padding.sm} / 1.5);
          }
          span {
            padding-top: 0;
          }
        }
      }
    }

    .btn-box {
      margin-top: ${(props) => props.theme.margin.base};
      display: flex;
      justify-content: center;

      button {
        border: none;
        border-radius: ${(props) => props.theme.borderRadius.sm};
        color: ${(props) => props.theme.colors.white};
        background-color: ${(props) => props.theme.colors.purple};
        font-size: ${(props) => props.theme.fontSize.base};
        padding: ${(props) => props.theme.padding.sm}
          ${(props) => props.theme.padding.xl};
        cursor: pointer;
        white-space: nowrap;
      }
    }
  }

  @media only screen and (max-width: 800px) {
    flex-direction: column;
    margin-left: ${(props) => props.theme.margin.base};
    margin-right: ${(props) => props.theme.margin.base};

    .img {
      width: 100%;
      margin-bottom: ${(props) => props.theme.margin.base};
    }

    .text {
      width: 100%;
    }
  }

  @media only screen and (max-width: 600px) {
    padding: ${(props) => props.theme.padding.base}
      ${(props) => props.theme.padding.lg};
  }
`;
