import {Outlet} from 'react-router-dom';
import styled from 'styled-components';
import {Header} from '../components/Header';

export const Admin = () => {
  return (
    <>
      <Header />
      <Container>
        <Outlet />
      </Container>
    </>
  );
};

const Container = styled.section``;
